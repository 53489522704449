.loading-component-full-page {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    /*zoom: 2;*/
    transform: scale(2);
}

.loading-component-icon {
    width: 25px;
    position: absolute;
    margin-top: 7px;
    margin-left: 7px;
    opacity: 0.7;
    animation: pulse-zoom 10s linear infinite;


    /*width: 38px;*/
    /*position: absolute;*/
    /*margin-top: 1px;*/
    /*margin-left: 1px;*/
    /*animation: wormhole-spin 5s linear infinite;*/
    /*animation-direction: reverse;*/

}

.loading-component .spinner-border {
    width: 2.5rem;
    height: 2.5rem;
    /*border-top: 0.35em solid rgba(249,49,84,1);*/
}

.loading-component-full-page  .spinner-border{
    /*width: 1.5rem;*/
    /*height: 1.5rem;*/
    border: 0.1em solid;
    border-top: 0.1em solid rgba(249,49,84,1);
    /*border-left: 0.15em solid rgba(249,49,84,0.7);*/
    /*border-bottom: 0.15em solid rgba(249,49,84,0.3);*/
    /*border-top: 0.15em solid #f93154;*/
    /*border-left: 0.15em solid #00B74A;*/
    border-right: 0.1em solid transparent;
    /*border-right: 0.15em solid transparent;*/
    /*border-bottom: 0.15em solid #F93154;*/

}