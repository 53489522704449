
.todo-row {
    display: flex;
}
.todo-row-checkbox {
    /*flex: 0.7;*/
    font-size: 1.3em;
    padding: 5px;

}
.todo-row-input {
    /*flex: 9;*/
    flex:1;
    border: 0;
    outline: none;
    background: transparent;
    cursor: pointer;
    resize: none;
    overflow:hidden;
}
.todo-row-checked {
    color: #ccc;
}
.todo-row-checked .todo-row-input {
    color: #ccc;
    text-decoration: line-through;

}

.todo-sort-placeholder {
    /*height: 30px;*/
    border: 1px dashed black;
    background: #eee;
}
.todo-sort-placeholder-last {

    background: #eee;
}

.todo-sort-moving {
    display: none;
    /*position: absolute;*/
    /*background: white;*/
    /*border: 1px dashed #ccc;*/
    /*margin-left: 50px;*/
}

.todo-row-link,
.todo-row-importance-icon {
    padding: 5px;
    font-size: 1.2em;
    cursor: pointer;
}

.todo-row-link {
    font-size: 1.00em;
    padding-top: 7px;
}
.todo-row-checked .todo-row-importance-icon {
    /*display: none;*/
    color: #757575!important;
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
}
.todo-importance-standard .todo-row-importance-icon {
    opacity: 0.2;
}

.todo-row-checked.todo-importance-standard .todo-row-importance-icon {
    display: none;
}

.todo-component-text-mode {
    width: 100%;
    height: 100%;
    flex:1;
}

.todo-row-element-handle {
    padding-top: 7px;
    width: 14px;
    height: 30px;
    overflow: hidden;
    cursor: grabbing;
    transform: scale(0.8);
}

.io-touch .todo-row-element-handle {
    transform: scale(1.2);
    padding-right: 20px;
    display: inline-block;
    padding-left: 10px;

}

.todo-row-element-dragging {
    /*background: red;*/
}

.todo-row-element-dragging-chosen {
    background: rgba(117,117,117,0.2);
}

.todo-row-input-container {
    flex: 1;
    overflow-x: auto;
}

.io-mobile .focus-full-screen .todos-container-contents {
    padding-bottom: 50px;
}


/**
opcja 1
opcja 2
opcja 3
opcja 4
opcja 5
opcja 6
opcja 7
opcja 8

 */