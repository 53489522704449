.select-icon-component-list {
    width: 450px;
    height: 300px;
    overflow-y: auto;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 10px;
    max-width: 100%;
}



.select-icon-component i {
    font-size: 3em;
    padding: 15px;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    max-height: 76px;
    /*flex: 1 1;*/
    margin-left: 5px;
    margin-right: 5px;
}


.select-icon-component {
    padding: 10px;
}

.select-icon-component {

}
