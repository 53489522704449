/*.app-status-component {*/
/*    position: absolute;*/
/*    right: 29px;*/
/*    top: 37px;*/
/*    z-index: 10;*/
/*    font-size: 1em;*/
/*    pointer-events: none;*/
/*}*/

.app-status-component .loading-component {
    position: relative;

}
.app-status-component .spinner-border {
    opacity: 0;
    transition: all 1s;
    /*width: 2.9rem !important;*/
    /*height: 2.9rem !important;*/
    position: absolute;
    /* top: 31px; */
    /* top: 2%; */
    /* right: 2%; */
    /* right: 22px; */
    pointer-events: none;
    /*font-size: 0.7em;*/
    /* margin-right: -4px; */
    /* margin-top: 1px; */
    /*margin-left: -4px;*/
    /*margin-top: -3.6px;*/
    /*margin-left: -0.25rem;*/
    /*margin-top: -0.2445566rem;*/
    /*top: -50%;*/
    /*margin-left: -50%;*/
    width: 42px;
    height: 42px;
    margin-top: -3px;
    margin-left: -3px;


}
.app-status-component.pending .spinner-border {
    opacity: 1;

}

.app-status-component .loading-component-icon {
    display: none !important;
}