.tooltips-component {
    position: fixed;
    /*bottom: 10px;*/
    z-index:9999;
    /*width: 100%;*/
    text-align:center;
    pointer-events: none;
    /*left:50%;*/
    /*display:flex;*/
    /*flex-direction: column;*/


    color: #fff;
    padding: 6px 16px;
    font-size: 0.7em;
    background-color: #131313;
    border-radius: .25rem;
    text-transform: uppercase;


}
