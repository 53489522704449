.note-txt-component {
    display: flex;
}
.note-txt-component .app-node-body {
    flex: 1;
    display: flex;
    flex-direction: column;

}
.note-txt-component .note-txt-contents {
    display: flex;
    flex:1;
}


.note-txt-component textarea{
    resize: none;
    flex:1;
    background: none;
    border: none;
    color: rgb(79, 79, 79);
}

.note-txt-component textarea:focus {
    outline: none;
}

.note-txt-component-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.note-txt-component-container .comments-component {
    margin-right: 10px;
}
.note-txt-component-container .comments-component-opened {
    margin-left:25px;
    margin-top:-5px;
}
.note-txt-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: -2px;
    margin-right: 5px;
}


.note-txt-color-button.map-node-tools-button {
    top: 5px;
    position: absolute;
}
.note-txt-color-button {
    display: inline-block;
    width: 17px;
    height: 17px;

    border-radius: 100%;
    border: 1px solid #333333;
    cursor: pointer;
}
.note-txt-component .map-node-tools-buttons {
    background: transparent !important;;
}


.note-txt-color-picker {
    top: 45px;
    position: absolute;
    /* right: 10px; */
    /* background: red; */
    /* left: -7px; */
    right: 0;
    width: 170px;
    text-align: right;
}

.note-txt-color-picker .note-txt-color-button  {
    margin-left: 5px;
    width: 25px;
    height: 25px;
}
