.select-emoji-component-list {
    width: 450px;

    overflow-y: visible;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 10px;
    max-width: 100%;
}

.select-emoji-component-data {
    height: 300px;
    margin-top: 15px;
    overflow-y: auto;
}


.select-emoji-component-list-item {
    font-size: 3em;
    padding: 10px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    max-height: 76px;
    margin-left: 5px;
    margin-right: 5px;
    /*flex: 1 1;*/
}


.select-emoji-component {
    padding: 10px;
}

.select-emoji-component {

}



