/*.map-node-tool-button {*/
/*    position:absolute;*/
/*    right: 15px;*/
/*    top: 15px;*/
/*    cursor: pointer;*/
/*    color: #ccc;*/
/*}*/

.map-node-tools-buttons {
    position: absolute;
    right: -5px;
    top: 0px;
    z-index: 5;
    background: #fbfbfb;
    padding-left: 10px;
    /*top: 12px;*/
}

.map-node-tools-buttons .badge {
    margin-right: 10px;
}
.map-node-tool-button {
    cursor: pointer;
    margin-right: 10px;
}

.map-node-tools-component .user-icon-component {
    /*position: absolute;*/
    /*top: -10px;*/
    /*left:-20px;*/
    /*float:right;*/
    /*margin-top:-1px;*/
    /*float:right;*/
    /*transform: scale(0.7);*/
    zoom: 0.7;
    margin-right: 5px;
    /*margin-left: 10px;*/
}