.map-node-flag {
    z-index:0;
}

.map-node-flag .app-node-body {
    /*position: absolute;*/
    /*top: 50%;*/

}


.map-node-flag .app-node-title {
    text-align: center;
    border: none;
    font-size: 1.4em;
    font-weight: bold;
    margin-top: 30px;
}

.map-node-flag-contents {
    text-align: center;
    position: absolute;
    margin-top: 20px;
   width: 100%;

}
.map-node-flag .map-node-flag-icon {
    font-size: 5em;
    /*margin-top: -150px;*/
    /*position: absolute;*/
    /*margin-left: -40px;*/
    color: rgba(0,255,0,0.5);
}
.app-node.map-node-flag .app-node-body {
    margin: auto !important;;
}
.app-node.map-node-flag {
    overflow: visible;
}

.map-node-flag-options {
    /*position: absolute;*/
    /*top: -80px;*/
    /*text-align: center;*/
    /*width: 100%;*/
}

.map-node-flag-options .map-node-flag-open-options {
    position: absolute;
    /*top: -80px;*/
    /*margin-left: -15px;*/
    right: 20px;
    /*right: 20px;*/
    cursor: pointer;
}

.map-node-flag-options-opened {
    background: none !important;
    /*position: absolute;*/
    /*margin-top: -200px;*/
    /*margin-left: 110%;*/
    /*margin-top: -200px;*/

}
.map-node-flag-options-color {
    width: 100%;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.app-node.map-node-flag.map-node-mobile.focus-full-screen {
    background: white;
}
.map-node-flag .map-node-full-screen-button ,
.io-mobile .map-node-flag-open-options {
    display: none;
}


.focus-full-screen.map-node-flag.focus {
    background: white;
}
.focus-full-screen .map-node-flag-options-opened {
    /*position: absolute;*/
    position: static;
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*z-index:9999999;*/
    margin-left:0;
    /*padding-top:100px;*/
    /*margin-top:200px;*/
    /*background: white;*/
}

.map-node-flag-options-choose-type {
    margin: auto;
    display: block;
    box-shadow: none !important;
}
.map-node-flag-options-choose-type .btn-outline-dark {
    opacity: 0.75;
}

.map-node-flag .map-node-flag-icon.map-node-flag-emoji {
    color: black;
    line-height: 1em;
}
