.app-search i.map-node-icon,
.app-search span.map-node-icon

{
    font-size: 4em;
}

.app-search .form-outline .form-control.form-control-lg:not([value=""])~.form-label,
.app-search .form-outline .form-control.form-control-lg:focus~.form-label {
    transform: translateY(-1.25rem) translateY(0.1rem) scale(.8) !important;
    background: white;
    /*border: 1px solid red;*/
    display: block;
    z-index:1;
}

.app-search .form-outline .form-control.form-control-lg~.form-label {

}



.app-search img.map-node-icon {
    height: 65px;
}
.app-search .mini-map {
    border: 1px solid #757575;
}

.app-search .selected .mini-map {
    border: 1px solid #1266f1;
}

.app-search .mini-map-in .mini-map {
    /*float:right;*/
}

.app-search .row > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-search-results {
    max-height: 50vh;
    overflow-y: auto;
}

.app-search-results-item {
    cursor: pointer;
    transition: background-color 0.3s;
}
.app-search-results-item-hover
/*.app-search-results-item:hover*/
{
    background: #E0E0E0;
}



@media screen and (max-width: 1024px) {
    /*.app-search-results {*/
    /*    position: fixed;*/
    /*    left: 0;*/
    /*    width: 100vw;*/
    /*    margin-top:5px;*/
    /*}*/
}

.app-search-closest-flag {
    position: absolute;
    top: 0px;
    /*margin-left: 50px;*/
}
.app-search-closest-flag  span{
    margin-left: 5px;
}

.app-search {
    margin-top: -5px;
}


@media screen and (max-width: 1024px) {
    .app-search-focused {
        position: absolute;
        top: 2%;
        left: 2%;
        right: 2%;
        z-index: 10;

    }
    .app-search-results {
        max-height: 80vh;
    }

}
