.share-component {
    margin-right: 10px;
}

.share-component-invitations {
    margin-right: 10px;
}

.share-component-invitations i {
    padding: 5px;
    margin: 5px;
}
.invitation-info {
    margin-right: 10px;
}

.share-component-list-item {
    display: flex;
}
.io-mobile .share-component-list-item .text-warning {
    margin-left: 15px;

}
.io-mobile .share-component-list-item {
    display: block;
    text-align: left;
}
.share-component-list-item-title {
    flex: 1;
}

.share-component-list-item i {
    margin-right: 5px;
}

.share-component-list-item a {

}

.share-component-list {
    width: 400px;
    max-width: 100%;
}

.share-component .loading-component {
    width: 400px;
    max-width: 100%;
    display: block;
    text-align: center;
}
