


.gradient-border {

}
/*app-files-uploading*/
.app-files-uploading::after {
    --border-width: 3px;

    position: absolute;
     content: "";
     top: calc(-1 * var(--border-width));
     left: calc(-1 * var(--border-width));
     z-index: -1;
     width: calc(100% + var(--border-width) * 2);
     height: calc(100% + var(--border-width) * 2);

    background: linear-gradient(
            0deg,
            rgba(255,255,255,0),
            rgba(255,255,255,0),
            rgba(255,255,255,0),
            rgba(68,138,255,0.1),
            rgba(255,255,255,0),
            rgba(255,255,255,0),
            rgba(255,255,255,0)
    );
     background-size: 300% 300%;
     background-position: 50% 0%;
     border-radius: calc(2 * var(--border-width));
     animation: moveGradient 1s backwards infinite;
}


@keyframes moveGradient {
    100% {
        background-position: 100% 100%;
    }
}
