.map-choose-component {
    /*background: red;*/
    /*position: absolute;*/
    /*bottom: 220px;*/
}
.map-choose-component > .col-10 {
    cursor: pointer;
}
.map-choose-button {
    cursor: pointer;
}

.map-select-button {
    cursor: pointer;
}

.map-add-button {
    display:flex;
    align-items:center;
    justify-content: center;
    min-height: 230px;
}
.map-add-button button {
    /*zoom: 1.5;*/
    transform: scale(1.5);
}


/*.map-choose-items-container {*/
/*    !*margin-left: 20px;*!*/
/*    text-align: center;*/
/*}*/
/*.map-choose-item {*/
/*    display: inline-block;*/
/*    width: 230px;*/
/*    height: 230px;*/
/*    !*float:left;*!*/
/*    text-align:center;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    margin-right: 15px;*/
/*    margin-left: 15px;*/
/*    margin-bottom: 30px;*/
/*}*/
/*.map-choose-items-container {*/
/*    padding-top: 20px;*/
/*}*/
.map-choose-items-container .card-header {
    /*margin-bottom:5px;*/
    /*padding-bottom: 15px;*/
}
.map-choose-items-container .card-title {
    /*margin-top: 5px;*/
    line-height: 1.6em;
}
.map-choose-items-container .mini-map {
    margin-left: auto;
    margin-right: auto;
    /*min-width: 230px;*/
    /*max-width: 230px;*/
    /*margin-right: 20px;*/
    /*margin-left: 20px;*/
    /*margin-top: 20px;*/
}
.map-choose-items-container .map-menu-button {
    cursor: pointer;
}
.map-choose-items-container .card-body {
    padding: 1rem !important;
    padding-bottom: 0.8rem !important;
}


@media screen and (min-width: 1200px) {


        .map-choose-items-container {
            min-width: 1100px;
        }

}