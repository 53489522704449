.modal-component {
    /*position: fixed;*/
    /*left: 0;*/
    /*top: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*background: rgba(255,255,255,0.8);*/
    /*z-index: 9999999;*/
    /*display: flex;*/
}

.modal-component .modal-container {
    /*background: red;*/
    /*width: 80%;*/
    /*height: 80%;*/
    /*margin-left: 10%;*/
    /*margin-top: 10%;*/

}

.modal {
    display: flex !important; /* flex centers but problems on mobile */
    background: rgba(0,0,0,0.5);
    vertical-align: center;
    align-content: center;
    /*height: 100%;*/
    backdrop-filter: blur(5px);
    overflow-y: auto;
    z-index:1000;
}
.modal .modal-dialog {
    /*flex: 1;*/
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    /*height: 100%;*/
}
.modal-content {
    max-height: 100vh;
    overflow: auto;
    max-width: 96vw;
}

.modal {

    user-select: text;
}
