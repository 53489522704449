.log-component {
    display: flex;
    flex-direction: column;
}
.log-add-textarea {
    flex: 1;
}
.logs-container {
    flex: 9;
    overflow-y: auto;
}

.node-log-component {

}

.log-add-textarea {
    /*position: absolute;*/
    /*bottom: 10px;*/
    /*width: 98%;*/
    background: white !important;
}

.log-row-date {
    flex: 2;
}
.log-row-content {
    flex: 8;
    white-space: pre-wrap;
}

.log-row {
    display: flex;
    user-select: text;
}
.log-component .form-notch-middle {
    min-width: 51px;
    min-height: 50px;
}
