.notifications-component {
    position: fixed;
    bottom: 10px;
    z-index:9999;
    /*width: 100%;*/
    text-align:center;
    pointer-events: none;
    left:50%;
    display:flex;
    flex-direction: column;

}

.notifications-component .alert {
    width: 200px;
    margin-left: -100px;
    /*display:inline-block;*/
}


.modal-confirm-buttons .btn-outline-danger {
    margin-right: 15px;
}