.user-notifications-component {
    margin-right: 10px;
}
.user-notifications-component-dialog {
    position: absolute;
    background: white;
    border: 1px solid red;
}

.user-notifications-component-item {
    cursor: pointer;
}

.user-notification-unread {
    font-weight: bold;
}


.user-notifications-component-item .user-notifications-component-item-date {
    /*font-size: small !important;*/
    float: right;
    font-weight: normal;
    margin-left: 10px;
    text-align: right;
    color: #333;
    padding-right: 0;
}


.user-notifications-component-item-title {
    margin-left: 10px;
}

.user-notifications-component-mark-all-as-read {
    display: block;
    text-align: center;
    padding: 5px;
    border-top: 1px solid #ccc;
    color: #1266f1;
    cursor: pointer;
    margin-top: 10px;
}

.user-notification-list-container {
    max-height: 50vh;
    overflow-y: auto;
}