.todo-icon-component {
    margin-right: 15px;
    display: inline-block;
    position: relative;
    width: 31px;
    height: 20px;
}
/*.todo-icon-component i {*/
/*    font-size: 2em;*/
/*    cursor: pointer;*/
/*    display:inline-block;*/
/*    position:absolute;*/
/*}*/

.todo-icon-component .dropdown-item i {
    margin-right: 10px;
}