/*.feedback-component {*/
    /*margin-right: 15px;*/
    /*display: inline-block;*/
    /*position: relative;*/
    /*width: 31px;*/
    /*height: 20px;*/
    /*position: fixed;*/
    /*right: 0;*/
    /*top: 50%;*/

/*}*/

/*.feedback-component .btn {*/
/*    !*transform: rotate(-90deg);*!*/
/*}*/

/*.feedback-component i {*/
/*    margin-right: 5px;*/
/*}*/

/*@media screen and (max-width: 1600px) {*/
/*    .feedback-button-title {*/
/*        display: none;*/
/*    }*/
/*}*/

.feedback-component form {
    min-width: 400px;
}

.feedback-component .rating {
    outline: none;
    display: flex;
    height: 40px;
}
.feedback-component .rating-stars {
    flex: 1;
}
.feedback-component .rating i {
    font-size: 2em;
    margin-left: 5px;
    cursor: pointer;
    margin-top: 3px;
}

.feedback-component .rating .rating-title {
    font-size: 1.6em;
    margin-left: 10px;
}

@media screen and (max-width: 420px) {
    .feedback-component form {
        min-width: auto;
        /*width: 87vw;*/
    }
    .feedback-component .rating {
        display: block;
        height: auto;
    }
    .feedback-component .rating-title {
        margin-top: 10px;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .feedback-component .modal .modal-dialog {
        flex: 1 !important;
    }
}
