.comments-component-button {
    color: rgb(204, 204, 204);
    cursor: pointer;
}
.io-mobile .comments-component-opened {
    /*position: fixed;*/
    /*padding: 10px;*/
    /*background: white;*/
    /*z-index:999;*/
    /*border: 1px solid #ccc;*/
    /*margin-left:20px;*/
    /*margin-top:-10px;*/
    /*border-radius: 5px;*/
    width: 400px;
    max-width: 90vw;
    display: block;

}
.io-mobile .comments-component-opened .list-group {
    max-width: 100%;
}

.comments-component-user-icon {
    transform: scale(0.8);
}

.comments-component-comment-body {
    /*padding-left: 15px;*/
    overflow-wrap: break-word;

}


.comments-component-commment-date {
font-size: 0.8em;
    /*position: absolute;*/
    /*right: 5px;*/
    /*top: 5px;*/
    margin-right:5px;
    margin-top:3px;
}

.comments-component-opened .list-group{
    max-height: 80vh;
    max-width: 80vw;
    min-width: 40vw;
    overflow-y: auto;
}
.comments-component-opened .input-group{
    padding-top: 15px;
}

.comments-component-comment-header {
    text-align: center;
    display: block;
    margin-right: -15px;
}
.comments-component-comment-header .user-icon-component {
    transform: scale(0.8);
    /*pointer-events: none;*/
    display: block;
}
.comments-component-comment-header > * {
    /*float: right;*/
}
.comments-component-opened-container .comments-component-button-icon {
    color: red;
}
.comments-component-indicator {
    margin-left: 3px;
}


.comments-component .loading-component {
    width: 400px;
    max-width: 100%;
    display: block;
    text-align: center;
}
