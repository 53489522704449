.map-node-wormhole {
    z-index:0;
}

.map-node-wormhole .app-node-body {
    /*position: absolute;*/
    /*top: 50%;*/

}


.map-node-wormhole .app-node-title {
    text-align: center;
    border: none;
    font-size: 1.4em;
    font-weight: bold;
    /*margin-top: 140px;*/
}

.map-node-wormhole-contents {
    text-align: center;
    position: absolute;
    /*margin-top: -200px;*/
   width: 100%;


}



.map-node-wormhole img {

    max-height: 150px;
    max-width: 100%;

    animation: wormhole-spin 45s linear infinite;


    cursor: pointer;
}

.app-node.map-node-wormhole {
    overflow: visible;
}

.map-node-wormhole-options {
    /*position: absolute;*/
    /*top: -80px;*/
    /*text-align: center;*/
    /*width: 100%;*/
}

.map-node-wormhole-options .map-node-wormhole-open-options {
    position: absolute;
    /*top: -80px;*/
    /*margin-left: -15px;*/
    right: 20px;
    /*right: 20px;*/
    cursor: pointer;
}

.map-node-wormhole-options-opened {
    position: absolute;
    /*margin-top: -200px;*/
    margin-left: 110%;
    margin-top: -200px;

}
.map-node-wormhole-options-color {
    width: 100%;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}