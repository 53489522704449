/*
https://mdbootstrap.com/docs/standard/
 */

/* login */

.text-right {
  text-align: right;
}



@keyframes pulse-zoom {
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes wormhole-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


*::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #8d8989;
}

.radio-buttons {
  margin-left: 1rem !important;
}

.app-shadow {
  box-shadow: 0 6px 20px 0 rgba(0,0,0,.25),0 12px 40px 5px rgba(0,0,0,.2);
}

.no-shadows #notes-container .app-shadow {
  border: 1px solid black;
}

/*.app-shadow.app-map-node-saving {*/
/*  !*box-shadow: 0 0 0 rgba(204,169,44, 0.4);*!*/
/*  animation: pulse 2s infinite;*/
/*}*/

/*@keyframes pulse {*/
/*  0% {*/
/*    box-shadow: 0 6px 20px 0 rgba(0,0,0,.25),0 12px 40px 5px rgba(0,0,0,.2);*/

/*    !*box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);*!*/
/*  }*/
/*  70% {*/
/*    box-shadow: 0 6px 25px 0 rgba(0,100,0,.25),0 12px 30px 5px rgba(0,100,0,.2);*/

/*    !*box-shadow: 0 0 0 10px rgba(204,169,44, 0);*!*/
/*  }*/
/*  100% {*/
/*    box-shadow: 0 6px 20px 0 rgba(0,0,0,.25),0 12px 40px 5px rgba(0,0,0,.2);*/

/*    !*box-shadow: 0 0 0 0 rgba(204,169,44, 0);*!*/
/*  }*/
/*}*/

/* login */

#app {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow:hidden;

  user-select: none;
}

#app.app-pending-operations {
  background: red;
}

#map-canvas {
  position: absolute;

}
body {
  overflow: hidden;
}

.map-log {
  position: fixed;
  bottom: 0;
  right: 0;
  background: lightblue;
}

.app-node {
  position: fixed;
  border-color: transparent;
  z-index:1;
}
.app-node-body {
  position: relative;
  transform-origin: center center;
}
.app-node-body.app-node-body-zoomed-out {
  /*position: initial;*/
  /*width: 100%;*/
  /*height: 100%;*/
}

.app-node.focus-full-screen {
  position: fixed;
  z-index:999 !important;
  top: 0 !important;
  padding-top: 50px !important;
  left:0 !important;
  right: 0 !important;
  bottom:0 !important;
  width: 100% !important;
  height: 100% !important;
}

.map-node-full-screen-toolbar {
  text-align: center;
  font-size: 2em;
  /* padding-bottom: 20px; */
  position: fixed;
  width: 100%;
  top: 0;
  /*background: white;*/
  height: 50px;
  z-index:9999 !important;

}
.map-node-full-screen-toolbar i{
  cursor: pointer;

}
.map-node-mobile-edit-overlay {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:2;
  background: rgba(0,0,0,0.01);
  display: flex;
  vertical-align: center;
  align-items: center;
  justify-content: center;

}
.map-node-mobile-edit-overlay button{
  zoom:1.34;
}

.app-node.selected.focus {
  border-color: #00b74a;
  z-index:3;
}
.app-node.selected {
  border-color: #FFA900;
  z-index: 2;
}
.map-grid {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /*cursor: move;*/
}

.app-note-content {
  height: 100%;
  overflow-y: auto;
}
.app-note-content:read-write:focus {
  outline: none;
}
.app-node {
  /*transition: all 0.1s ease 0s;*/
  overflow:auto;
}


.app-map-dragging {
  cursor: move;
}

.app-map-moving .app-node{
  transition: none;
  pointer-events: none;
}


.app-node-title {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 2px dashed #ccc;
  padding-bottom: 7px;
  margin-bottom: 7px;
}





/*.card {*/
/*  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.45) !important;*/
/*}*/


/*.tools-bottom-right {*/
/*  position: fixed;*/
/*  bottom: 2%;*/
/*  right: 2%;*/
/*  z-index: 10;*/
/*  transform: scale(1.8);*/
/*}*/

.tools-bottom-right {
  position: fixed;
  bottom: 0;
  /* right: 2%; */
  z-index: 100;
  /*transform: scale(1.8);*/
  /*background: white;*/
  /*width: 100%;*/
  right: 0;
  /*border-top: 1px solid black;*/
  margin-bottom: 20px;
  /*padding-top: 20px;*/
  /*padding-left: 300px;*/
  margin-right: 20px;
  text-align: right;

}
.tools-bottom-right .add-map-object-button {
  /*transform: scale(1.2);*/

  margin-right: 5px;
  overflow: visible;
  transform: scale(1.2);
  margin-left: 15px;
}
.tools-bottom-right .add-map-object-button .additional-icon {
  /*bottom: 0;*/
  /*right: 0px;*/
  /*position:absolute;*/
  bottom: 7px;
  right: 8px;
  position: absolute;
  font-size: 10px;
}

.tools-bottom-right .add-map-object-img-button .additional-icon {
  color: white;
}

.tools-bottom-right .add-map-object-img-button {
  box-shadow: none !important;
}

.tools-bottom-toggle-button {
  display: none;
}

@media screen and (max-width: 1024px) {
  .tools-bottom-toggle-button {
    display: inline-block;
  }
  .add-map-object-button:not(.tools-bottom-toggle-button) {
    display: none;
  }
  .tools-bottom-right-opened .add-map-object-button {
    display: block;
    margin-top: 20px;
  }

  /*.tools-bottom-right-opened .add-map-object-button.tools-bottom-toggle-options {*/
  /*  display: none;*/
  /*}*/

}


/*.selected-main-tools {*/
/*  display: inline-block;*/
/*  border-right: 5px dashed gray;*/
/*  !*background: red;*!*/
/*  height: 70px;*/
/*  margin-right: 20px;*/
/*  padding-right: 20px;*/
/*}*/


.tools-bottom-left {
  position: fixed;
  bottom: 2%;
  left: 2%;
  z-index: 100;
  /*transform: scale(1.8);*/
}
.tools-bottom-left > div {
  display: inline-block;
}

.zoom-tools button {
  display: block;
  margin-top: 5px;
}

.zoom-tools {
  position: absolute;
  /*top: 60px;*/
  margin-left: 11px;

  height: 110%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  top: 0px;
  left: 200px;
  margin-top: -5%;

}

.mini-map-closed .mini-map-container {
  height: 0;
  opacity: 0;

}

.mini-map-closed {

}

.mini-map-closed .zoom-tools {
  /*display: none !important;*/
  left: -10px;
  display: block !important;;
  top: -45px;
  height: 45px;
}
.mini-map-closed .zoom-tools button {
  display: inline-block;
  margin-left: 10px;
}

@media screen and (max-width: 1024px) {

  .no-mobile {
    display: none !important;
  }


  .zoom-tools {

    height: auto;
    bottom: 0;
    top: auto;
  }
}

/*.tools-top-right {*/
/*  position: fixed;*/
/*  z-index: 101;*/
/*  top: 2%;*/

/*  right: 2%;*/
/*  margin-top: 5px;*/
/*  !*height: 50px;*!*/
/*  !*transform: scale(1.5);*!*/
/*}*/
/*.tools-top-right .btn-group {*/
/*  box-shadow: none;*/

/*}*/
/*.tools-top-right .btn {*/
/*  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2), 0 2px 10px 0 rgba(0,0,0,.1);*/
/*  !*transform: scale(1.2);*!*/
/*}*/
/*.tools-top-right .btn:after {*/
/*  display: none !important;*/
/*}*/

/*.tools-top-center {*/
/*  position: fixed;*/
/*  z-index: 100;*/
/*  top: 2%;*/
/*  left: 25%;*/
/*  width: 50%;*/
/*  right: 25%;*/
/*  height: 50px;*/
/*  !*transform: scale(1.5);*!*/
/*}*/


/*@media screen and (max-width: 1024px) {*/
/*  .tools-top-center {*/

/*    left: 2%;*/
/*    width: 75%;*/
/*    right: 25%;*/
/*    height: 50px;*/
/*    !*transform: scale(1.5);*!*/
/*  }*/
/*}*/

/*.tools-top-center input {*/
/*  background: white !important;*/
/*}*/

.top-tools {
  position: fixed;
  z-index: 101;
  top: 2%;
  /*background: blue;*/
  right: 2%;
  left: 2%;
  /*opacity: 0.5;*/
}

.modal-opened .top-tools {
  z-index: 100;
}
.top-tools input {
  background: white !important;
}

.top-tools-right .btn-group {
  box-shadow: none;

}
.top-tools-right .btn {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2), 0 2px 10px 0 rgba(0,0,0,.1);
  /*transform: scale(1.2);*/
}
.top-tools-right .btn:after {
  display: none !important;
}



/*.top-tools > div {*/
/*  background: red;*/
/*  border: 1px solid black;*/
/*}*/

.mini-map {
  background: white;
  overflow: hidden;
  position: relative;
  /*border: 1px solid black;*/
}

.mini-map__drawing-position {
    position: absolute;
    border: 2px solid black;
    background: rgba(163, 191, 227, 0.4);
    pointer-events: none;
}

.mini-map__highlight {
  position: absolute;
  border: 2px solid red;
  z-index:100;
  pointer-events: none;
}

@media screen and (max-width: 1024px) {
  .mini-map-canvas {
    /*transform: scale(0.5);*/
  }

}

.map-action {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  /*background: rgba(0,0,0,0.1);*/
}

.map-action-selectArea,
.map-action-selectAreaPosition {
  background: #1266f1;
  border: 1px solid #1266f1;
  opacity: 0.2;
  pointer-events: none;
}

.app-note-placeholder {
  pointer-events: none;
  position:absolute;
  font-size: 30px;
}

.selected-tools-handle {
  position: fixed;
  z-index: 10;
}

.selected-tools-handle-area {
  position: fixed;
  border: 1px solid #1266f1;
  pointer-events: none;

}
.selected-tools-move-handle {
  margin-left: -75px;
  font-size: 2em;
  margin-top: 25%;
  background: white;
  padding: 10px;
  border-radius: 100%;
  cursor: move;
}

.selected-tools-resize-handle {
  width: 10px;
  height: 10px;
  background: #1266f1;
  position: absolute;
  display: block;
}
.selected-tools-resize-handle-8 {
  right: -5px;
  bottom: -5px;
  cursor: nw-resize;
  pointer-events: initial;
}
.selected-tools-resize-handle-6 {
  left: -5px;
  bottom: -5px;
  cursor: ne-resize;
  pointer-events: initial;
}
.selected-tools-resize-handle-3 {
  right: -5px;
  top: -5px;
  cursor: ne-resize;
  pointer-events: initial;
}
.selected-tools-resize-handle-1 {
  left: -5px;
  top: -5px;
  cursor: nw-resize;
  pointer-events: initial;
}
.selected-tools-resize-handle-2,
.selected-tools-resize-handle-4,
.selected-tools-resize-handle-5,
.selected-tools-resize-handle-7 {
  display: none;
}
.app-loader {
  /*max-width: 500px;*/
  /*display: block;*/
  /*margin: auto;*/
  /*margin-top: 20px;*/
}
.app-loader form {
  /*margin-top: 20px;*/
}
.alert i {
  margin-right: 10px;
}



.img-icon {
  width: 100%;

}


.app-node-component-zoom-in-icon {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: white;
  flex-direction: column;
  z-index:3;
}
.app-node-component-zoom-in-title {
  font-size: 3em;
  /* margin-bottom: 51px; */
  vertical-align: middle;
  /* background: red; */
  /*line-height: 3em;*/
  /*margin-top: -1em;*/
  /*position: absolute;*/
  /*top: 0;*/
  pointer-events: none;
}

.app-node-component-zoom-in-icon i {
  font-size: 6em;
  cursor: pointer;

}

.dropdown-menu.open-toolbar {
  display: block !important;
  left: initial !important;
  right: 0 !important;
  margin-top: 10px !important;
}

.debug-toggle {
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 9999999;
}

.debug {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  padding: 20px;
  z-index:9999999;
}


.no-shadows #notes-container * {
  box-shadow: none !important;
}
.no-icons i {
  display: none !important;
}
.app-node-password-locked-contents .btn {
  /*margin-top: 0px;*/
}
.app-node-password-locked-contents {


}

.app-loader-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

}

.app-loader-container .app-loader {

}

.app-loader-background-container {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  overflow: hidden;
  background-color: black;
  z-index:-2;
}

.app-loader-background {

  background-image: url("img/wormhole-inverted-big.png");
  animation: wormhole-spin 145s linear infinite;
  /*zoom:1.6;*/
  position: absolute;
  top: 50%;
  left:50%;
  margin-left:-1500px;
  margin-top:-1500px;

  width: 3000px;
  height: 3080px;

  z-index:-1;
}

@media screen and (min-width: 2200px) {
  .app-loader-background {
    zoom:1.6;
  }
}


@media screen and (max-width: 1800px) {
  .app-loader-background {
    /*transform: scale(1.0);*/
    zoom:1.0;
  }
}

@media screen and (max-width: 1023px) {
  .app-loader-background {
    /*transform: scale(0.6);*/
    zoom:0.6;
  }
}


@media screen and (max-width: 767px) {
  .app-loader-background {
    zoom: 0.3;
  }
}


.app-loader-container::before {

}
.app-loader h1,.app-loader label{
  color: white;
}

.app-loader  input {
  background: white !important;
}


.tools-bottom-left .card-body {
  padding: 0;
}

.hidden {
  height: 0 !important;
  opacity: 0;
}


@media screen and (-webkit-min-device-pixel-ratio:0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
    /*background: #eee;*/
  }
}

.no-pointer-events {
  pointer-events: none;
}

#logo-main-container {
  text-align: center;
  overflow:hidden;
  height: 150px;
  margin-bottom: 20px;
}
#logo-main {
  height: 150px;
  margin: auto;
}

.password-font {
  font-family: 'password';
  /*font-size: 0.9em;*/
}
.password-font::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}


/*@media screen and (min-width: 1024px) {*/

/*  [data-tooltip-bottom]:hover:before,*/
/*  [data-tooltip]:hover:before {*/
/*    opacity: 1;*/
/*    !*transition: all 0.1s ease 0.5s;*!*/
/*    visibility: visible;*/
/*  }*/
/*  [data-tooltip-bottom]:before,*/
/*  [data-tooltip]:before {*/
/*    content: attr(data-tooltip);*/
/*    position: absolute;*/

/*    !*left: 50%;*!*/
/*    white-space: nowrap;*/
/*    opacity: 0;*/
/*    z-index: 99999;*/
/*    visibility: hidden;*/
/*    text-align:center;*/

/*    color: #fff;*/
/*    padding: 6px 16px;*/
/*    font-size: 0.7em;*/
/*    background-color: #131313;*/
/*    border-radius: .25rem;*/
/*    top: -90%;*/
/*    right: 0;*/
/*  }*/

/*  [data-tooltip-bottom],*/
/*  [data-tooltip] {*/
/*    position: relative;*/
/*  }*/

/*  [data-tooltip-bottom]:before {*/
/*    top: 90% !important;*/
/*    content: attr(data-tooltip-bottom) !important;*/


/*  }*/
/*}*/


.password-protected-mobile-info {

}

/*.map-node-locked-up:not(.focus-full-screen).map-node-mobile .password-unlock-component {*/
/*  display: none;*/
/*}*/


* {
  text-size-adjust: none !important;
  -webkit-text-size-adjust:none !important;
}

.main-tools-toggle-show-hidden {
  margin-right: 10px;
}

.map-node-hidden {
  /*opacity: 0.5;*/
  border-color: #B23CFD !important;
}

.app-component-zoom-alert {
  position: fixed;
  z-index: 999;
  bottom: 0;
  background: #f93154;
  left: 33%;
  width: 34%;
  color: white;
  font-size: 0.8em;
  padding: 5px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

}

.terms-link {
  color: white !important;
  text-decoration: underline;
}
.confirm-account-button {
  margin-right: 10px;
}

.keyboard-shortcut {

  border-radius: 0.25rem;


  color: rgba(0, 0, 0, 0.7);
  background-color: #e5e5e5;
  box-shadow: #d1d5db 0px -4px 0px inset, rgba(0, 0, 0, 0.4) 0px 1px 1px;
  pointer-events: none;
  display: inline-block;
  font-family: monospace;
  text-transform: none;
}

.app-search .keyboard-shortcuts {

  top: 8px;
  position: absolute;
  right: 10px;
}
.app-search .keyboard-shortcut {
  /*padding: 0.15rem 0.25rem;*/
  font-size: 0.75em;
  /*float: right;*/
    margin-left: 5px;

}
.tooltips-component .keyboard-shortcut{
  margin-left: 5px;
  margin-right: -5px;
  font-size: 1.1em;
  padding: 0.15rem 0.45rem;
  box-shadow: #999b9f 0px -4px 0px inset, rgb(0 0 0 / 60%) 0px 1px 1px;


}

.io-mobile .keyboard-shortcut {
  display: none !important;;
}

._dom-util-tmp-textarea {
    position: fixed;
  left: 0;
    /*top: 0;*/
   /*z-index: 9999;*/
  /*border: 1px solid red !important;*/
  pointer-events: none;
  opacity: 0;
  top: -9999px;
}

/*.app-node.app-map-node-warm {*/
/*  border-color: rgba(255,109,0,1) !important;*/
/*}*/

/*.app-node.app-map-node-hot  {*/
/*  border-color: rgba(255,19,0,1) !important;*/
/*}*/

.todo-main-menu-dropdown {
  max-height: 50vh;
  overflow-y: auto;
}
